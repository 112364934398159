import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55')
];

export const server_loads = [];

export const dictionary = {
		"/(admin)": [5,[2]],
		"/(admin)/championships": [35,[2]],
		"/(admin)/championships/create": [37,[2]],
		"/(admin)/championships/[championshipid=integer]": [36,[2]],
		"/(admin)/documents": [38,[2]],
		"/(admin)/documents/create": [40,[2]],
		"/(admin)/documents/[documentid=integer]": [39,[2]],
		"/(admin)/events": [41,[2]],
		"/(admin)/events/create": [42,[2]],
		"/login": [55],
		"/(admin)/riders": [43,[2]],
		"/(admin)/riders/create": [45,[2]],
		"/(admin)/riders/[riderid=integer]": [44,[2]],
		"/(admin)/settings": [46,[2]],
		"/(admin)/teams": [47,[2]],
		"/(admin)/teams/[teamid=integer]": [48,[2]],
		"/(admin)/tracks": [49,[2]],
		"/(admin)/tracks/[trackid=integer]": [50,[2]],
		"/(admin)/tracks/[trackid=integer]/checkpoints": [51,[2]],
		"/(admin)/tracks/[trackid=integer]/loops": [52,[2]],
		"/(admin)/tracks/[trackid=integer]/loops/create": [53,[2]],
		"/(admin)/transponders/[transponder_long=integer]": [54,[2]],
		"/(admin)/[eventid=integer]": [6,[3]],
		"/(admin)/[eventid=integer]/dashboard": [7,[3]],
		"/(admin)/[eventid=integer]/documents": [8,[3]],
		"/(admin)/[eventid=integer]/documents/create": [10,[3]],
		"/(admin)/[eventid=integer]/documents/[documentid=integer]": [9,[3]],
		"/(admin)/[eventid=integer]/gateway": [11,[3]],
		"/(admin)/[eventid=integer]/gateway/[decoderid=integer]": [12,[3]],
		"/(admin)/[eventid=integer]/organisation/groups": [13,[3]],
		"/(admin)/[eventid=integer]/organisation/groups/create": [14,[3]],
		"/(admin)/[eventid=integer]/organisation/starterlists": [15,[3]],
		"/(admin)/[eventid=integer]/organisation/teams": [16,[3]],
		"/(admin)/[eventid=integer]/organisation/teams/[teamid=integer]": [17,[3]],
		"/(admin)/[eventid=integer]/organisation/timetable": [18,[3]],
		"/(admin)/[eventid=integer]/passings": [19,[3]],
		"/(admin)/[eventid=integer]/passings/create": [21,[3]],
		"/(admin)/[eventid=integer]/passings/recalculate": [22,[3]],
		"/(admin)/[eventid=integer]/passings/[passingid=integer]": [20,[3]],
		"/(admin)/[eventid=integer]/races": [23,[3]],
		"/(admin)/[eventid=integer]/races/create": [25,[3]],
		"/(admin)/[eventid=integer]/races/[raceid=integer]": [24,[3]],
		"/(admin)/[eventid=integer]/reports/healthinsurances": [26,[3]],
		"/(admin)/[eventid=integer]/reports/opendeposits": [27,[3]],
		"/(admin)/[eventid=integer]/reports/unreturnedTransponders": [28,[3]],
		"/(admin)/[eventid=integer]/riders": [29,[3]],
		"/(admin)/[eventid=integer]/riders/create": [31,[3]],
		"/(admin)/[eventid=integer]/riders/[riderid=integer]": [30,[3]],
		"/(admin)/[eventid=integer]/trackside": [32,[3,4]],
		"/(admin)/[eventid=integer]/trackside/groups": [33,[3,4]],
		"/(admin)/[eventid=integer]/transponders/[transponder_long=integer]": [34,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';